import React, { SVGAttributes } from 'react'

const CraftIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      fill="#221e33"
      {...props}
    >
      <path
        id="cauldron"
        d="M27,16.875a12.884,12.884,0,0,0-2.156-6.75h.468a1.688,1.688,0,0,0,0-3.375H1.688a1.688,1.688,0,0,0,0,3.375h.468A12.874,12.874,0,0,0,0,16.875a8.59,8.59,0,0,0,2.747,6.114c-.1.3-.375.612-1.081.636A1.688,1.688,0,0,0,1.688,27,4.625,4.625,0,0,0,5.5,25.023,16.577,16.577,0,0,0,13.5,27a16.577,16.577,0,0,0,8-1.977A4.625,4.625,0,0,0,25.313,27a1.688,1.688,0,0,0,0-3.375,1.006,1.006,0,0,1-1.054-.641A8.587,8.587,0,0,0,27,16.875Zm-23.625,0c0-3.012,2.06-5.7,2.982-6.75H20.64c.92,1.055,2.985,3.756,2.985,6.75,0,3.659-4.637,6.75-10.125,6.75S3.375,20.534,3.375,16.875Zm4.5-15.187A1.688,1.688,0,1,1,9.563,3.375,1.688,1.688,0,0,1,7.875,1.688ZM15.75,2.813A1.688,1.688,0,1,1,17.438,4.5,1.688,1.688,0,0,1,15.75,2.813Z"
        fill="inherit"
      />
    </svg>

  )
}

export default CraftIcon
